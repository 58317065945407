import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO'
import Layout from '../components/Layout'
import EventsList from '../components/EventsList'
import { BottomLineGoogleAd } from '../components/styles/GoogleAdsCommon.styles'
import GoogleAdBlock from '../components/ContentBlocks/GoogleAdBlock'
import { futureEvents } from '../util/timeDateUtils'

const EventsListPage = ({ data, pageContext }) => {
  const doc = data.prismicEventsLandingPage.data
  const events = data.allPrismicEvent?.edges
  if (!doc || !events) {
    return null
  }

  const twitterImage = doc.twitter_image?.url ? doc.twitter_image : null
  const facebookImage = doc.facebook_image?.url ? doc.facebook_image : null
  const seoTitle = doc.meta_title?.text
    ? doc.meta_title.text
    : doc.title?.text
    ? doc.title.text
    : null
  const seo_embed_code = doc.seo_embed_code?.text ?? null
  const adBlockId =
    data.prismicGeneralSiteSettings?.data?.events_landing_page_google_ad
      ?.document?.id
  const interstitialId =
    data.prismicGeneralSiteSettings?.data
      ?.events_landing_page_interstitial_ad_tag?.document?.id
  return (
    <>
      {/* <pre>{JSON.stringify(events, false, 2)}</pre> */}
      <SEO
        seo={{
          description: doc.meta_description,
          keywords: doc.meta_keywords,
          title: seoTitle,
          twitter_image: twitterImage,
          facebook_image: facebookImage,
          seo_embed_code: seo_embed_code,
        }}
      />
      <Layout>
        <EventsList doc={doc} events={futureEvents(events)} />
        {adBlockId && <BottomLineGoogleAd id={adBlockId} />}
        {interstitialId && <GoogleAdBlock id={interstitialId} />}
      </Layout>
    </>
  )
}

export const query = graphql`
  query EventsListQuery {
    prismicGeneralSiteSettings {
      data {
        events_landing_page_google_ad {
          document {
            ... on PrismicGoogleAdTag {
              id
            }
          }
        }
        events_landing_page_interstitial_ad_tag {
          document {
            ... on PrismicGoogleAdTag {
              id
            }
          }
        }
      }
    }
    prismicEventsLandingPage {
      uid
      id
      data {
        body_text {
          richText
          text
        }
        title {
          richText
          text
        }
        seo_embed_code {
          text
        }
      }
    }
    allPrismicEvent(
      sort: { fields: data___event_date, order: ASC }
      filter: { data: { omit_from_listings_page: { eq: false } } }
    ) {
      edges {
        node {
          id
          data {
            omit_from_listings_page
            start_time
            end_time
            event_date
            end_date
            event_city
            event_state
            event_address_line_1
            event_address_line_2
            event_zip
            event_venue
            event_title {
              text
              richText
            }
            event_link {
              id
              link_type
              uid
              type
              url
            }
            event_link_text
            event_image {
              alt
              url
              thumbnails {
                mobile {
                  url
                }
              }
            }
            event_description {
              text
              richText
            }
          }
        }
      }
    }
  }
`

export default EventsListPage
